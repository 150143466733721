<template>
  <v-col md="12">
    <v-card elevation="2">
      <v-card-title>
        <v-container fluid>
          <v-row>
            <v-col cols="9">
              <v-text-field
                  v-model="filters.address"
                  label="Поиск (имя, адрес, ид, номер по АБ, номер по РУ(РУ-7))"
                  required
                  @change="applyFilters"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="3">
              <v-select
                  v-model="filters.configuration_id"
                  :items="configurations"
                  label="Фильтр по конфигурации"
                  item-text="name"
                  item-value="id"
                  @change="applyFilters"
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="3">
              <v-select
                  v-model="filters.engineer_id"
                  :items="engineers"
                  label="Фильтр по специалисту"
                  item-text="full_name"
                  item-value="id"
                  @change="applyFilters"
              ></v-select>
            </v-col>
            <v-col cols="12" md="3">
              <v-menu
                  v-model="menu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      v-model="filters.inventory_date"
                      label="Дата обследования"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                  ></v-text-field>
                </template>

                <v-date-picker
                    v-model="filters.inventory_date"
                    locale="ru-ru"
                    :first-day-of-week="1"
                    @input="menu = false"
                    @change="applyFilters"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" md="2">
              <v-select
                  v-model="filters.sort_by"
                  :items="sort_by_items"
                  label="Поле сортировки"
                  item-text="name"
                  item-value="id"
                  @change="applyFilters"
              ></v-select>
            </v-col>
            <v-col cols="12" md="2">
              <v-select
                  v-model="filters.sort_dir"
                  :items="sort_dir_items"
                  label="Фильтр по специалисту"
                  item-text="name"
                  item-value="id"
                  @change="applyFilters"
              ></v-select>
            </v-col>
            <v-col cols="12" md="2">
              <v-btn
                  @click="resetFilter"
                  title="Сбросить фильтры"
                  class="mx-2"
                  fab
                  dark
                  color="primary">
                <v-icon dark>
                  mdi-cached
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card-title>
      <v-card-text>
        <v-simple-table>
          <template v-slot:default>
            <thead>
            <tr>
              <th class="text-left">Статус</th>
              <th class="text-left">Номер по АБ</th>
              <th class="text-left">Наименование</th>
              <th class="text-left">Тип объекта</th>
              <th class="text-left">Адрес</th>
              <th class="text-left">Районное управление</th>
            </tr>
            </thead>
            <tbody>
            <tr
                v-for="item in inventoryObjects"
                :key="item.id"
            >
              <td>
                <ObjectState :id="item.state_id" :name="item.state_name"></ObjectState>
              </td>
              <td>{{ item.subscriber_base_number }}</td>
              <td>
                <router-link :to="`/inventory-object/${item.id}`">
                  {{ item.name }}
                </router-link>
              </td>
              <td>{{ item.event_at }}</td>
              <td>{{ projectName(item.project_id) }}</td>
              <td>{{ objectTypeName(item.object_type_id) }}</td>
              <td>{{ item.address }}</td>
              <td>{{ item.district }}</td>
            </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>

      <base-pagination
        action="inventory_objects/getInventoryObjects"
        mutation="inventory_objects/SET_PAGE"
        :meta="meta"/>
    </v-card>

    <v-btn
        color="primary"
        dark
        fixed
        bottom
        right
        fab
        @click="$router.push('/create-inventory-object')"
    >
      <v-icon>mdi-plus</v-icon>
    </v-btn>
  </v-col>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import ObjectState from "@/components/InventoryObject/ObjectState.vue";
import BasePagination from "@/components/BasePagination.vue";

export default {
  name: "InventoryObjectsList",
  components: {BasePagination, ObjectState},
  data() {
    return {
      fields: [
        {key: 'subscriber_base_number', label: 'Номер по АБ'},
        {key: 'name', label: 'Наименование'},
        {key: 'event_at', label: 'Дата обследования'},
        {key: 'project_id', label: 'Проект'},
        {key: 'object_type_id', label: 'Тип объекта'},
        {key: 'address', label: 'Адрес'},
        {key: 'district', label: 'Районное управление'},
        {key: 'controls', label: 'Управление'}
      ],
      filters: {
        address: null,
        engineer_id: null,
        inventory_date: null,
        configuration_id: null,
        sort_by: 'subscriber_base_number',
        sort_dir: 'desc'
      },
      menu: false,
      sort_by_items: [
        {
          id: 'event_at',
          name: 'Дата обследования'
        },
        {
          id: 'id',
          name: 'Идентификатор'
        },
        {
          id: 'district',
          name: 'Районное управление'
        },
        {
          id: 'subscriber_base_number',
          name: 'Номер по АБ'
        }
      ],
      sort_dir_items: [
        {
          id: 'asc',
          name: 'По возрастанию'
        },
        {
          id: 'desc',
          name: 'По убыванию'
        }
      ]
    }
  },
  computed: {
    ...mapState({
      meta: state => state.inventory_objects.meta
    }),
    ...mapGetters({
      inventoryObjects: 'inventory_objects/getInventoryObjects',
      configurations: 'object_types/getObjectTypes',
      projectName: 'projects/getProjectName',
      objectTypeName: 'object_types/getObjectTypeName',
      engineers: 'engineers/getEngineers',
    })
  },
  mounted() {
    this.applyFilters();
    this.$store.dispatch('projects/getProjects');
    this.$store.dispatch('object_types/getObjectTypes');
    this.$store.dispatch('engineers/getEngineers');
  },
  methods: {
    resetFilter() {
      this.filters = {
        address: null,
        engineer_id: null,
        inventory_date: null,
        configuration_id: null,
        sort_by: 'event_at',
        sort_dir: 'desc'
      };
      this.$store.commit('inventory_objects/SET_FILTER_PARAMS', this.filters);
      this.applyFilters();
    },
    applyFilters() {
      this.$store.commit('inventory_objects/SET_FILTER_PARAMS', this.filters);
      this.$store.dispatch('inventory_objects/getInventoryObjects');
    }
  }
}
</script>

<style scoped>

</style>
